import React, { useState, useContext, useEffect } from "react";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import { Container, Row, Col } from "reactstrap";
import { GetAPI } from "../../API/APICalling";
import { GlobalContext } from '../../App.js';
import { ReadMore } from '../../utils/common';

export default function FAQ() {
  const globalContext = useContext(GlobalContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    const FnGetCMSContent = async () => {
      globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
      const result = await GetAPI(`cms/faq`);
      globalContext.loaderDispatch({ type: 'STOP_LOADING' });

      if (result) {
        if (result.error) {
          globalContext.notifications('error', result.error);
        } else if (result.data) {
          setData(result.data.content.body);
        }
      }
    };
    FnGetCMSContent();
  }, []);

  return (
    <>
      <Container>
        <Row>
          <Col className="ml-auto mr-auto text-left" md="10">
            <h2 className="big-heading text-center" id="faq">FAQs on Cleaning Services</h2>
            <hr className="hr-large" />
            <p className="description text-center pb-10">
              Many of our customers have specific questions about our professional maid services. Here are just a few of the Frequently asked questions we hear at Whissh.
            </p>

            {Array.isArray(data) && data.map((item, index) => (
              <React.Fragment key={index}>
                <div className="mt-20">
                  <p className="title-2 text-center">{item.title}</p>
                </div>
                <Accordion allowZeroExpanded>
                  {item.faq.map((item2, index2) => (
                    <AccordionItem key={index2}>
                      <AccordionItemHeading>
                        <AccordionItemButton><h3 style={{ display: "inline", color: "#1f70db", fontSize: "14px", marginBottom: "0", fontWeight: "600" }}>{item2.q}</h3></AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p className="description" dangerouslySetInnerHTML={{ __html: item2.a }}></p>
                      </AccordionItemPanel>
                    </AccordionItem>
                  ))}
                </Accordion>
              </React.Fragment>
            ))}
          </Col>
        </Row>
        {/* <Row>
          <Col className="ml-auto mr-auto text-left" md="10"> */}
            <section class="home_testimonials">
              <h2 className="big-heading text-center">Your Testimonials</h2>
              <div class="home_testimonial-container">
                  <div class="home_testimonial">
                      <p class="home_client-name">A Long-Time Customer since 2021</p>
                      <div class="home_stars">★★★★★</div>
                      <p>"We are very happy with the service provided by our assigned cleaner Meme. She's very punctual and cleans the house really well. She'll take care of the fine details in cleaning and she remembers our preference so we do not need to repeat to her every session. She strikes a good balance between taking the initiative in the cleaning tasks and confirming with us before she starts. The Whissh backend support office is also very responsive and follow up promptly with our requests. Overall, it's a very good experience for us. We will definitely going to continue engaging Whissh."</p>
                  </div>
                  <div class="home_testimonial">
                      <p class="home_client-name">A Busy Professional</p>
                      <div class="home_stars">★★★★★</div>
                      <p>"Extremely happy and satisfied with their service as a first timer.</p>
                      <p>I was enquiring for a thorough deep cleaning for whole house post aircon installation where the whole house are dusty and Whissh responded quite fast. Mary Grace was so helpful with the enquiry and quotation was given a couple of hours later.</p>
                      <p>She even followed up once the job is completed.</p>
                      <p>A team of 4 turned up on the scheduled day on time. They brought their own cleaning equipments, solutions, wipes and even they brought their own ladder! Everyone knew their duties and they split doing their work quietly.</p>
                      <p>Very impressive, they have done a very good job, everything is clean and shining and its good to know they used eco-friendly solutions. Thanks to Simran (team leader and also the expert in glass cleaning), Ei ei Hnin, Aye Hsu, and Zin Wai.</p>
                      <p>A couple of hours before they complete, Roslan (Supervisor) came and did an inspection and I am very pleased with the work done. No spot has been missed.</p>
                      <p>Without a doubt, Whissh will come into our mind whenever we need a deep or regular cleaning service."</p>
                  </div>
                  <div class="home_testimonial">
                      <p class="home_client-name">A Working Adult from A Dual Income Family</p>
                      <div class="home_stars">★★★★★</div>
                      <p>"I have engaged a few house cleaning services. Hence I can see the difference in the specific areas in my house that I need to be cleaned.</p>
                      <p>With minimal requests before the cleaning session started, I was very impressed with the way my house was cleaned. I asked for deep cleaning service from Whissh and I was very impressed with the way my two helpers helped me.</p>
                      <p>For working parents who are working from Monday to Saturday full time, Sunday is a day we wish to rest. House cleaning is a work by itself. Hence, we do not have the energy to clean on a Sunday. We did only spot cleaning. Which mean, dust will collect at hardly-cleaned areas.</p>
                      <p>As Ive mentioned, the minimal requests I asked are areas with the dirt that are so visible, the ladies were able to help me. What impressed me most was the areas I did not have to say, they did it so merticulously. They really worked hard within 5 hours.</p>
                      <p>When my husband came home, he can smell the difference of a clean home. We both agreed we cannot do it by ourselves to have the air and cleanliness we need.</p>
                      <p>Thank you Whissh. My helpers did a wonderful job."</p>
                  </div>
              </div>
            </section>
          {/* </Col>
        </Row> */}
      </Container>
    </>
  );
}

// Code Backup Below

// import React, { useState, useContext, useEffect } from "react";

// // reactstrap components
// import {
//   //Card,
//   Container,
//   Row,
//   Col
// } from "reactstrap";
// import { GetAPI } from "../../API/APICalling";
// import { GlobalContext } from '../../App.js';
// import { ReadMore } from '../../utils/common';

// // core components

// export default function FreeTextSection() {
//   const globalContext = useContext(GlobalContext);
//   const [data, setData] = useState({});

//   useEffect(() => {
//     window.scrollTo({
//       top: 0,
//       behavior: 'smooth'
//     });
//     const FnGetCMSContent = async () => {
//       globalContext.loaderDispatch({ type: 'START_LOADING', payload: false });
//       var result = await GetAPI(`cms/home-body-4`);
//       console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAAA")
//       if (result) {
//         console.log(result)
//         globalContext.loaderDispatch({ type: 'STOP_LOADING' });
//         if (result.error) {
//           //globalContext.notifications('error', result.error);
//         }
//         else if (result.data) {
//           console.log(result.data)
//           setData(result.data.content)
//         }
//       }
//     }
//     FnGetCMSContent();
//   }, []);

//   return (
//     <>
//       {data.body &&
//         <ReadMore>{data.body}</ReadMore>
//       }
//     </>
//   );
// }

