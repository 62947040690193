import React, { useState, useContext, useEffect } from "react";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import { Container, Row, Col } from "reactstrap";
import { GetAPI } from "../../API/APICalling";
import { GlobalContext } from '../../App.js';
import { BASE_URL } from '../../constant.js';
import { DateFormat } from '../../utils/common';

export default function BlogSection() {
  const globalContext = useContext(GlobalContext);
  const [articles, setArticle] = useState([]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    const FnGetAritcles = async () => {
      var result = await GetAPI(`home/blogsbycat/homepage`);
      if (result) {
        if (result.data) {
          setArticle(result.data)
        }
      }
    }
    FnGetAritcles();
  }, []);

  return (
    <>
      <Container>
        <Row>
          <Col md="12">
            <div className="mt-20">
              <h2 className="big-heading text-center">Related Articles</h2>
              <hr className="hr-large" />
            </div>
          </Col>
        </Row>
        {articles && <div className="related-article-container">
          {articles && articles.map((item, index) => {
            return (
              <div key={index} className="blog-content blog-block" style={{ flex: 1, margin: "10px" }}>
                <a href={item.pageurl === "" ? "#" : `${BASE_URL}blog/${item.pageurl}`}>
                  <div className="blog-cover-img">
                    <img src={item.imageurl} alt={item.title} />
                  </div>
                </a>
                <div className="blog-text">
                  <span className="blog-date">{DateFormat(item.blogDate, 'DD-MMM-YYYY')}</span>
                  <a href={item.pageurl === "" ? "#" : `${BASE_URL}blog/${item.pageurl}`}>
                    <h3 style={{ overflow: "hidden", fontSize: "18px", fontWeight: "600", display: "-webkit-box", WebkitLineClamp: "2", WebkitBoxOrient: "vertical", color: "#3a3a3c" }}>{item.title}</h3>
                  </a>
                  <p className="blog-desc description" dangerouslySetInnerHTML={{ __html: item.summary }}></p>
                  <a className="btn btn-info" href={item.pageurl === "" ? "#" : `${BASE_URL}blog/${item.pageurl}`}>
                    Read More
                  </a>
                </div>
              </div>
            )
          })}
        </div>}
      </Container>
    </>
  );
}